@import url('https://fonts.googleapis.com/css?family=Krub:200,400,600,700');

* {
    padding: 0;
    font-family: 'Krub', sans-serif;
    --primary-background: #f1f0f5;
    --text-color: #000000;
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: 'Krub', sans-serif;
    background: var(--primary-background);
    color: var(--text-color);
}

a {
    text-decoration: none;
}
